:root {
  --mediaWhite: #e0e1dd;
  --background: #0d1b2a;
  --navBackground: rgba(0, 0, 0, 0.63);
}

.wholeApp {
  background-color: var(--background);
  background-size: cover;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.primaryLogo {
  max-width: 300px;
  display: flex;
  margin: auto;
  border-radius: 20px;
  margin-top: 30px;
}
h1 {
  font-size: 3em;
}
h2 {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
a {
  text-decoration: none;
  color: var(--mediaWhite);
}

/* NAV */

.wholeNav {
  color: white;
  background-color: var(--navBackground);
  padding-left: 7%;
  padding-right: 7%;
}
.innerNav {
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* FORM */

.form {
  color: var(--mediaWhite);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  padding: 60px;
}
.form h2 {
  margin: 0;
}
.infoForm {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* FOOTER */

.wholeFooter {
  color: var(--mediaWhite);
  display: flex;
  justify-content: end;
  padding: 7px;
}
.wholeFooter p {
  font-size: smaller;
}

/* -----------DASHBOARD------------ */

.wholeDash {
  min-height: 600px;
  display: flex;
  justify-content: space-around;
  margin: auto;
}
.leftDash {
  width: 100%;
  min-width: 400px;
}

/* title */

.wholeTitle {
  width: 90%;
  min-width: 300px;
  color: var(--mediaWhite);
  margin: auto;
  text-align: center;
  text-transform: uppercase;
}

/* user card */

.userCardOuter,
.newsFeed {
  width: 90%;
  min-width: 400px;
  height: 300px;
  background-color: var(--mediaWhite);
  margin: auto;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}
.username {
  color: black;
}
.avatarImageContainer {
  display: flex;
  justify-content: center;
  background-color: #0d1b2a;
  aspect-ratio: 1/1;
  border-radius: 75%;
  width: 40%;
  max-width: 150px;
}
.avatarImageOuterContainer {
  display: flex;
  justify-content: center;
}

/* news feed */

.newsFeed {
  margin-top: 20px;
  height: auto;
  min-height: 400px;
}

/* friends list */

.outerWholeFriendsList {
  margin-right: 6%;
  margin-top: 20px;
}
.wholeFriendsList {
  background-color: var(--mediaWhite);
  border-radius: 10px;
  width: 250px;
  text-align: center;
  height: 500px;
  overflow-y: auto;
}
.addFriendButton {
  margin-top: 7px;
  width: 100%;
  background-color: #1b263b;
  border-radius: 6px;
  border: 1px solid #fff;
  display: inline-block;
  color: #fff;
  padding: 6px 24px;
}
.addFriendButton:hover {
  color: var(--background);
  background-color: var(--mediaWhite);
}
.addFriendButton:active {
  position: relative;
  top: 1px;
}
.addFriendTextbox {
  width: 97%;
}
.friendCard {
  color: var(--mediaWhite);
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgb(36, 43, 65);
  border-radius: 10px;
  margin: 10px;
}
.friendCard img {
  width: 50px;
}
.friendReqCard {
  display: flex;
  background-color: red;
}

.friendAvatarImage {
  width: 20%;
}

.friendReqOpt {
  margin: auto;
}

/* -------------MEDIA-------------- */

@media screen and (max-width: 900px) {
  .outerWholeFriendsList {
    display: none;
  }
  .userCardOuter,
  .newsFeed {
    min-width: 300px;
    height: 200px;
  }
  .avatarImageContainer {
    width: 80px;
  }
}
